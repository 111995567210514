import React from 'react'
import { Link } from 'gatsby'
import { Card, Button, Col, Row } from 'react-bootstrap'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Span from '../components/Span'
import '../components/css/promo.css'
import BuenoManoPromo from 'images/bueno-mano.jpg'
import ReferralBanner from 'images/referal-times-two.png'
import Seo from '../components/Seo'
import DigiPadalaAgentKNB from 'images/digipadala-agent-knb.png'
import TagulanPromo from '../images/tagulan-main-promo-page.jpg'
import SixSikSaPapremyo from '../images/sixsik-sa-papremyo-main-promo-page.jpg'

const Promo = () => (
  <div>
    <Seo
      title="Digipay PH | Promos"
      description="Digipay is a digital payments and financial services platform with
      a mobile wallet, an industry leading biller ecosystem,
      and an interoperable backend."
      keywords="digipay.ph/promos, Digipay, Promos, Digipay Promos, Raffle, Agents"
    />
    <Header></Header>
    <div className="promo">
      <h1 align="center" id="font-weight-bold padding-bottom-md">
        DIGIPAY PROMOS
        <hr />
      </h1>
      <Row className="promo__items">
        <Col {...componentProps.cardItemWrapperProps}>
          <Card className="digipay-stories__story-content">
            <Card.Img
              src={SixSikSaPapremyo}
              {...componentProps.cardItemImageProps}
            />
            <Card.Body>
              <Card.Title className="digipay-stories__story-title">
                <Span color="#eb3236" className="text-uppercase">
                  sixsik sa papremyo anniversary promo
                </Span>
                <br />
                <Span>November 1-December 31, 2023</Span>
              </Card.Title>
              <Card.Text className="digipay-stories__story-description">
                Process transactions using Digipay Agent App to earn raffle
                entries and get a chance to win exciting prizes!
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Link to="/promos/SixSikSaPapremyo/">
                <Button {...componentProps.readMoreButtonProps}>
                  Read More
                </Button>
              </Link>
            </Card.Footer>
          </Card>
        </Col>
        <Col {...componentProps.cardItemWrapperProps}>
          <Card className="digipay-stories__story-content">
            <Card.Img
              src={TagulanPromo}
              {...componentProps.cardItemImageProps}
            />
            <Card.Body>
              <Card.Title className="digipay-stories__story-title">
                <Span color="#eb3236" className="text-uppercase">
                  Digitindahan sa tag-ulan
                </Span>
                <br />
                <Span>June 15-July 31, 2023</Span>
              </Card.Title>
              <Card.Text className="digipay-stories__story-description">
                Get FREE store collaterals when you sign-up and top-up at least
                P500.00
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Link to="/promos/DigiTindahanTagulan/">
                <Button {...componentProps.readMoreButtonProps}>
                  Read More
                </Button>
              </Link>
            </Card.Footer>
          </Card>
        </Col>
        <Col {...componentProps.cardItemWrapperProps}>
          <Card className="digipay-stories__story-content">
            <Card.Img
              src={BuenoManoPromo}
              {...componentProps.cardItemImageProps}
            />
            <Card.Body>
              <Card.Title className="digipay-stories__story-title">
                <Span color="#eb3236">BUENO MANO TOP-UP PROMO</Span>
                <br />
                <Span>September 15-30, 2022</Span>
              </Card.Title>
              <Card.Text className="digipay-stories__story-description">
                Get additional Digipay Credits if you top up at least P1,500 and
                transact at least P1,000
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Link to="/promos/buenomanotopup/">
                <Button {...componentProps.readMoreButtonProps}>
                  Read More
                </Button>
              </Link>
            </Card.Footer>
          </Card>
        </Col>
        <Col {...componentProps.cardItemWrapperProps}>
          <Card className="digipay-stories__story-content">
            <Card.Img
              src={DigiPadalaAgentKNB}
              {...componentProps.cardItemImageProps}
            />
            <Card.Body>
              <Card.Title className="digipay-stories__story-title">
                <Span color="#eb3236">DIGIPADALA AGENT KNB?</Span>
                <br />
                <Span>February 1-28, 2022</Span>
              </Card.Title>
              <Card.Text className="digipay-stories__story-description">
                Get P300 Digipay credits when you convert from a regular agent
                to a DigiPadala Agent
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Link to="/promos/DigiPadalaAgentKNB/">
                <Button {...componentProps.readMoreButtonProps}>
                  Read More
                </Button>
              </Link>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </div>
    <Footer />
  </div>
)

const componentProps = {
  cardItemWrapperProps: {
    className: 'digipay-stories__story',
    align: 'center',
    md: 4,
  },
  cardItemImageProps: {
    variant: 'top',
    alt: 'guide-bg',
  },
  readMoreButtonProps: {
    className: 'digipay-stories__btn-read-more',
    variant: 'danger',
  },
}

export default Promo
